<template>
  <div class="container">
    <div class="tabs">
      <div class="tabs-head">
        <div
          class="tab"
          :class="{ active: activeTab === 0 }"
          @click="setActiveTab(0)"
        >
          IOS
        </div>
        <div
          class="tab"
          :class="{ active: activeTab === 1 }"
          @click="setActiveTab(1)"
        >
          Android
        </div>
      </div>
      <div class="tabs-container">
        <div class="tab" :class="{ active: activeTab === 0 }">
          <Table
            :thead="thead"
            :data="iosData"
            :searchBox="true"
            :excel="false"
            tableTitle="IOS"
          >
            <template v-slot:cell-name="{ row: data }">
              {{ data.name }}
            </template>
            <template v-slot:cell-account_user_name="{ row: data }">
              {{ data.account_user_name }}
            </template>
            <template v-slot:cell-account_password="{ row: data }">
              {{ data.account_password }}
            </template>
            <template v-slot:cell-delete="{ row: data }">
              <button
                class="btn btn-empty-danger"
                @click="deleteAccount(data.platform, data.id)"
              >
                <i class="icon icon-trash"></i>
              </button>
            </template>
          </Table>
        </div>
        <div class="tab" :class="{ active: activeTab === 1 }">
          <Table
            :thead="thead"
            :data="andoridData"
            :searchBox="true"
            :excel="false"
            tableTitle="Android"
          >
            <template v-slot:cell-name="{ row: data }">
              {{ data.name }}
            </template>
            <template v-slot:cell-account_user_name="{ row: data }">
              {{ data.account_user_name }}
            </template>
            <template v-slot:cell-account_password="{ row: data }">
              {{ data.account_password }}
            </template>
            <template v-slot:cell-delete="{ row: data }">
              <button
                class="btn btn-empty-danger"
                @click="deleteAccount(data.platform, data.id)"
              >
                <i class="icon icon-trash"></i>
              </button>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0,
      iosData: [],
      andoridData: [],
    };
  },
  created() {
    this.getIosData();
    this.getAndroidData();
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Geliştiri Hesapları",
        path: "/platform-accounts",
      },
    ]);
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index;
    },
    getIosData() {
      this.$axios.get("/developer-account/apple/").then((response) => {
        this.iosData = [];
        response.data.filter((item) => {
          this.iosData.push({
            id: item.id,
            name: item.name,
            account_user_name: item.account_user_name,
            account_password: item.account_password,
            platform: "apple",
          });
        });
      });
    },
    getAndroidData() {
      this.$axios.get("developer-account/google/").then((response) => {
        this.andoridData = [];
        response.data.filter((item) => {
          this.andoridData.push({
            id: item.id,
            name: item.name,
            account_user_name: item.account_user_name,
            account_password: item.account_password,
            platform: "google",
          });
        });
      });
    },
    deleteAccount(accountPlatform, accountId) {
      this.$axios
        .delete(`/developer-account/${accountPlatform}/${accountId}/`)
        .then(() => {
          this.getIosData();
          this.getAndroidData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  setup() {
    const thead = [
      {
        label: "Ad",
        value: "name",
        searchable: true,
      },
      {
        label: "Kullanıcı adı",
        value: "account_user_name",
        searchable: true,
      },
      {
        label: "Kullanıcı Şifre",
        value: "account_password",
        searchable: true,
      },
      
    ];
    return {
      thead,
    };
  },
};
</script>